import isBase64Image from 'utils/is/isBase64Image'

/**
 * Открывает изображение в новом окне
 * @param image
 */
function openImageInNewWindow(image: string) {
  // Если не Base64, открываем как обычную ссылку
  if (!isBase64Image(image)) {
    window.open(image, '_blank')

    return
  }

  // Создание blob-объекта
  const blob = new Blob([Uint8Array.from(
    atob(image.split(',')[1]),
    c => c.charCodeAt(0),
  )], { type: 'image/png' })

  // Создание временного URL для blob-объекта
  const url = URL.createObjectURL(blob)

  // Открытие нового окна с временным URL
  window.open(url, '_blank')

  // Освобождение ресурсов
  URL.revokeObjectURL(url)
}

export default openImageInNewWindow
