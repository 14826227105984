/**
 * Проверяет, является ли строка изображением в формате base64
 * @param str
 *
 * Чтобы определить, является ли строка изображением в формате Base64, проверяем несколько ключевых признаков:
 *
 * 1. Формат строки Base64: Строка должна начинаться с префикса, указывающего на формат изображения (например, data:image/jpeg;base64, data:image/png;base64, и т.д.).
 * 2. Длина строки: Строка должна иметь длину, кратную 4, и содержать только допустимые символы Base64.
 * 3. Отсутствие других данных: Строка не должна содержать символов, не принадлежащих Base64.
 */
function isBase64Image(str: string) {
  // Проверка на наличие префикса Base64 для изображений
  const base64Pattern = /^data:image\/(png|jpg|jpeg|gif|bmp|webp);base64,/

  if (!base64Pattern.test(str)) {
    return false
  }

  // Удаление префикса для дальнейшей проверки
  const base64Data = str.replace(base64Pattern, '')

  // Проверка длины строки
  if (base64Data.length % 4 !== 0) {
    return false
  }

  // Проверка на допустимые символы Base64
  const base64Regex = /^[A-Za-z0-9+/]+={0,2}$/

  return base64Regex.test(base64Data)
}

export default isBase64Image
